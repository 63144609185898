import { GetAllBiomarkersByPatientIdPath, GetApi } from "../../apis/Api";

async function GetAllBiomarkersByPatientId(patient_id){

    const biomarkers = await GetApi(GetAllBiomarkersByPatientIdPath(patient_id))
        .then(response => response.json())
        .then(data => convert(data.result));
        
    return biomarkers;
}

function convert(element){
    return {
        patient_name: element.patient_name,
        patient_id: element.patient_id,
        patient_ehr: element.patient_ehr,
        feedings: element.feedings.map((feeding) => {
            return {
                timestamp: feeding.timestamp,
                weight: feeding.weight,
                weight_uom: feeding.weight_uom,
                biomarkers: feeding.biomarkers.map((biomarker) =>{
                    return {
                        name: biomarker.biomarker_name,
                        id: biomarker.biomarker_id,
                        value: biomarker.biomarker_value,
                    };
                })
            };
        })
    };
}

export default GetAllBiomarkersByPatientId;