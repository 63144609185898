import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import { FiChevronDown, FiChevronUp, FiMinus, FiPlus } from "react-icons/fi";


export const CollapsibleCard = ({ children, title, subtitle, isFeedingCard = false, index,
    total,
    addFeedingCard,
    removeFeedingCard, }) => {

    const [showContent, setShowContent] = useState(true);

    const onArrowClick = () => {
        setShowContent(!showContent)
    }

    return (
        <div className="container-fluid neo_row_container">
            <div className="row neo_card_container m-0">
                <div className="col-md-12 mb-2">
                    <div className="neo_card_drop_container align-items-center">
                        <h2 className="neo_gen_title neo_h4_sm">
                            {title}
                        </h2>
                        {subtitle &&
                            <span className='neo_gen_text ms-4'>{subtitle}</span>
                        }
                        <span className='click ms-auto'>
                            {isFeedingCard &&
                                <>
                                    {index == total - 1 && <FiPlus className='neo_icon' size={30} onClick={()=>addFeedingCard()}/>}
                                    {index == total - 1 && index > 0 && <FiMinus className='neo_icon' size={30} onClick={()=>removeFeedingCard()}/>}
                                </>}
                            {showContent 
                                ? <FiChevronUp className='neo_icon' size={30} onClick={onArrowClick}/> 
                                : <FiChevronDown className='neo_icon' size={30} onClick={onArrowClick}/>}
                        </span>
                    </div>
                </div>
                {showContent && <div className="row ">
                    {children}
                </div>
                }
            </div>

        </div>
    )
}
