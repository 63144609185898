import React, { useEffect, useState } from 'react'
import { MixedChart } from './MixedChart'
import { WeeksBetweenDates } from '../../utilities/WeeksBetweenDates';
import { ConvertToGrams } from '../../utilities/Converter';

export const BiomarkersChart = ({feedings, conceptionDate}) => {

    console.log(JSON.stringify(feedings))

    const [maturationData, setMaturationData] = useState([]);
    const [weightData, setWeightData] = useState([]);

    useEffect(() => {
        getMaturationData()
        getWeightData()
    
    }, [feedings]);

    function getMaturationData(){
        const maturationEntries = [];
        const feedingsOrdered = feedings && feedings.length > 0 ? feedings.sort((a, b) => a.timestamp - b.timestamp) : [];

        feedingsOrdered.forEach(feed => {
            const weeks = WeeksBetweenDates(conceptionDate, feed.timestamp);
            const maturation = feed.biomarkers?.find((biomarker) => biomarker.name === "MATURATION")?.value || 0
            if (!isNaN(Number(maturation)) && maturation !== 0)
                maturationEntries.push({x: weeks, y: maturation});
        });

        setMaturationData(maturationEntries);
    }

    function getWeightData(){
        const weightEntries = [];
        const feedingsOrdered = feedings && feedings.length > 0 ? feedings.sort((a, b) => a.timestamp - b.timestamp) : [];

        feedingsOrdered.forEach(feed => {
            const weeks = WeeksBetweenDates(conceptionDate, feed.timestamp);
            weightEntries.push({x: weeks, y: ConvertToGrams(feed.weight, feed.weight_uom)});
        });

        setWeightData(weightEntries);
    }
    

    return (
        <div className='chart-wrapper'>
            <MixedChart weightData={weightData} maturationData={maturationData}/>
        </div>
    )
}
