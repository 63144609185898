import React, { useState } from 'react'
import { CollapsibleCard } from '../Collapsible/CollapsibleCard'
import FormatDate from '../../utilities/FormatDate'
import { CollapsibleBorderCard } from '../Collapsible/CollapsibleBorderCard'
import { Row } from 'react-bootstrap'
import { FlagsCard } from './FlagsCard'
import { FeedingDetailsCard } from './FeedingDetailsCard'
import { FeedingChart } from '../Charts/FeedingChart'
import { useGetFlags } from '../CustomHooks/useGetFlags'

export const FeedingSessionCard = ({ patient,
    index,
    total,
    addFeedingCard,
    removeFeedingCard }) => {

    const [currentFeedingIndex, setCurrentFeedingIndex] = useState(0);
    const [feeding, setFeeding] = useState(patient?.feedings[0]);
    const [granularity, setGranularity] = useState('full');

    const onSelectChange = (event) => {
        //setCurrentFeedingIndex(event.target.value);
        setFeeding(patient?.feedings[event.target.value])
    }

    const changeGranularity = (event) => {
        setGranularity(event.target.value);
    }

    const { isLoading: loadingFlags, flags } = useGetFlags(feeding.timestamp);

    return (
        <CollapsibleCard
            title={`ID: ${patient.ehr_patient_ref}`}
            subtitle={`Name:${patient?.name}  |  ${patient?.gestational_age} GA  |  ${patient?.birth_weight} ${patient?.weight_uom}`}
            isFeedingCard="true"
            index={index}
            total={total}
            addFeedingCard={addFeedingCard}
            removeFeedingCard={removeFeedingCard}>


            <div className="col-md-3">
                <FeedingDetailsCard feeding={feeding} />
            </div>
            {/* <div className="col-md-2">
                    <FlagsCard feeding={feeding} flags={flags} isLoading={loadingFlags} />
                </div> */}
            <div className="neo_inner_card_container col-md-9 neu_ut_gap_32">
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-inline align-items-center neu_ut_gap_12 pb-1">
                        <label htmlFor="neo_date" className="neo_input_label w-100">Date</label>
                        <select name="neo_date" id="" className="neo_input_select_medium " onChange={onSelectChange}>
                            {patient?.feedings.map((feeding, index) =>
                                <option value={index} key={feeding.timestamp} >{FormatDate(feeding.timestamp)}</option>
                            )}
                        </select>
                        <select name="neo_date" id="" className="neo_input_select" onChange={changeGranularity}>

                            <option value='full'>Full</option>
                            <option value='5m'>5 min</option>
                            <option value='1m'>1 min</option>
                            <option value='30s'>30 sec</option>
                            <option value='10s'>10 sec</option>

                        </select>
                    </div>

                    <div className='d-flex align-items-center'>
                        <span className="dot-pressure"></span>
                        <p className='neo_gen_text'>Suck & Swallow</p>
                        <span></span>
                        <span className="dot-breathing"></span>
                        <p className='neo_gen_text'>Breathing</p>
                        <span></span>
                        <span className="dot-flag"></span>
                        <p className='neo_gen_text'>Flags</p>
                    </div>
                </div>
                <div className="neo_ut_mt_24_mob">

                    <FeedingChart feeding={feeding} flags={flags} isLightGraph={false} granularity={granularity} />
                </div>
            </div>






            {/* <div className="neo_ut_mt_24 text-center">
                    <a href="#" className="neo_qv_btn"> Add feeding </a>
                </div> */}
        </CollapsibleCard >
    )
}
