
export const LoginTemplate = ({children}) => {



  const env = process.env.REACT_APP_ENV;

  const background = () => {
    switch(env){
      case "PROD" : return require('../../assets/LoginBackground.png')
      case "TEST" : return require('../../assets/test.png')
      case "DEV" : return require('../../assets/dev.png')
      default: return require('../../assets/LoginBackground.png')
    }
  }

  return (
    <section className="vh-100" style={{'backgroundImage': `url(${background()})`,}}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong" style={{"borderRadius": "1rem"}}>
              <div className="card-body p-5">

                <center>
                  <img className='LoginClientLogo'
                    src={require('../../assets/neoneur-logo.png')}
                    alt="Client Logo"
                  /> 
                  <p className='neo_brand_text'>Using Feeding Biomarkers to Assess Neuro-Development</p>
                </center>

                {children}

                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
