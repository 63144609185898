import React from 'react'
import { CollapsibleCard } from '../Collapsible/CollapsibleCard'
import { useGetCommentsByPatient } from '../CustomHooks/useGetCommentsByPatient'
import FormatDate from '../../utilities/FormatDate';
import { Loading } from '../Loading/Loading';

export const CommentsCard = ({ patient }) => {

    const { isLoading, comments } = useGetCommentsByPatient(patient.patient_id);

    return (
        <CollapsibleCard title="Comments">

            <div className="neo_comments_container neo_ut_scrollbar">
                <Loading isLoading={isLoading} />

                {
                    !isLoading && comments?.length === 0 && <center><p className="neo_gen_sm_text">None</p></center>
                }
                {
                    comments?.map((comment) =>
                        <div className="neo_comment_container" key={comment.timestamp}>
                            <div className="d-flex justify-content-between">
                                <p className="neo_gen_sm_text fw-bold neo_comment_inf">
                                    <span>{comment.user_name}</span>
                                    <span>{FormatDate(comment.timestamp)}</span>
                                </p>
                                <a href="#"><img src="./assets/img/neo_edit.svg" alt="" /></a>
                            </div>
                            <p className="neo_gen_text">
                                {comment.comment}
                            </p>
                        </div>
                    )
                }
            </div>
            {/* <div className="neo_ut_mt_24 text-center">
                <a href="#" className="neo_qv_btn"> Add comments </a>
            </div> */}
        </CollapsibleCard>
    )
}
