import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    BarController,  // Register the BarController
    ScatterController, // Register the ScatterController
    Tooltip,
    Legend
} from 'chart.js';

import { Chart } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    BarController,  // Registering BarController for "bar" charts
    ScatterController, // Registering ScatterController for scatter plots
    Tooltip,
    Legend
);




export const MixedChart = ({ weightData, maturationData }) => {

    const data = {
        datasets: [
            {
                type: 'bar',
                data: weightData, // Bar heights for weight
                backgroundColor: 'rgba(156, 183, 228, 1)', // Bar color
                yAxisID: 'y2', // Weight on second Y-axis
            },
            {
                type: 'scatter',
                data: maturationData, // Scatter plot data points for maturation index
                backgroundColor: 'rgba(69, 54, 133, 1)',
                pointBorderColor: 'rgba(69, 54, 133, 1)',
                pointBackgroundColor: 'rgba(69, 54, 133, 1)',
                pointRadius: [5], // Example of different circle sizes
                yAxisID: 'y1', // Maturation Index on first Y-axis
            }
        ]
    };

    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return value;
                    },
                    font: {
                        size: 8,
                        weight: 'bold'
                    }
                },
            },
            y1: {
                type: 'linear',
                position: 'left',
                max: 0,
                min: 100,
                title: {
                    display: false,
                    text: 'Maturation Index',
                },
                title: {
                    display: false
                },
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return value;
                    },
                    font: {
                        size: 8,
                        weight: 'bold'
                    }
                },
            },
            y2: {
                type: 'linear',
                position: 'right',
                beginAtZero: true,
                max: 14000,
                min: 0,
                title: {
                    display: false,
                    text: 'Weight (g)',
                },
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return Number(value/1000).toFixed(1) + "k";
                    },
                    font: {
                        size: 8,
                        weight: 'bold'
                    }
                },
            }
        }
    };

    return (
        <Chart type="bar" data={data} options={options} />
    );
}
