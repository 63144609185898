import { GetApi, GetLastTimetickPath} from "../../apis/Api";

async function GetLastTimetick(feeding_session_timestamp, patient_id) {

    // How many measurements has the feeding session
    const timetick = await GetApi(GetLastTimetickPath(feeding_session_timestamp, patient_id))
        .then(response => response.json())
        .then(data => data.result);

    return timetick;
};

export default GetLastTimetick;