import { useEffect } from 'react'
import { CollapsibleBorderCard } from '../Collapsible/CollapsibleBorderCard'
import GetMeasurements from '../../data_layer/repositories/Measurements/GetMeasurements'
import { useGetLastTimetick } from '../CustomHooks/useGetLastTimetick'
import { FormatMinAndSec } from '../../utilities/FormatDate'

export const FeedingDetailsCard = ({ feeding }) => {

    const {timetick} = useGetLastTimetick(feeding.timestamp, feeding.patient_id);

    return (
        <CollapsibleBorderCard title="Details">
            <div className="d-flex flex-wrap">
                <div className="neo_card_details_container flex-fill flex-wrap " >
                    <div>
                        <p className="neo_gen_text "><b>Paced feeding</b></p>
                        <p className="neo_gen_sm_text">{!feeding?.paced || feeding?.paced === 0 ? 'No' : 'Yes'}</p>
                    </div>
                    <div>
                        <p className="neo_gen_text "><b>Infant Weight</b></p>
                        <p className="neo_gen_sm_text">{feeding?.weight != 0 ? `${feeding?.weight} ${feeding?.weight_uom}` : '-'}</p>
                    </div>
                    <div>
                        <p className="neo_gen_text "><b>Volume</b></p>
                        <p className="neo_gen_sm_text">{feeding?.volume != 0 ? `${feeding?.volume} ${feeding?.volume_uom}` : '-'}</p>
                    </div>
                    <div>
                        <p className="neo_gen_text "><b>Nipple</b></p>
                        <p className="neo_gen_sm_text">{feeding?.nipple ? feeding?.nipple : '-'}</p>
                    </div>
                    <div>
                        <p className="neo_gen_text "><b>Nutrient</b></p>
                        <p className="neo_gen_sm_text">{feeding?.nutrient_type ? feeding?.nutrient_type : '-'}</p>
                    </div>

                    <div>
                        <p className="neo_gen_text "><b>Position</b></p>
                        <p className="neo_gen_sm_text">{feeding?.position ? feeding?.position : '-'}</p>
                    </div>

                    <div>
                        <p className="neo_gen_text "><b>Readiness to feed</b></p>
                        <p className="neo_gen_sm_text">{`${feeding?.readiness_score}/5`}</p>
                    </div>

                    <div>
                        <p className="neo_gen_text "><b>Quality of feed</b></p>
                        <p className="neo_gen_sm_text">{`${feeding?.quality_score}/5`}</p>
                    </div>

                    <div>
                        <p className="neo_gen_text "><b>Length of feeding</b></p>
                        <p className="neo_gen_sm_text">{FormatMinAndSec(timetick)}</p>
                    </div>
                </div>
            </div>

        </CollapsibleBorderCard>
    )
}
