import { useContext, useEffect, useState } from "react";
import GetMeasurements from "../../data_layer/repositories/Measurements/GetMeasurements";
import GetMeasurementsByPagination from "../../data_layer/repositories/Measurements/GetMeasurementsByPagination";
import GetLastTimetick from "../../data_layer/repositories/Measurements/GetLastTimetick";

export const useGetLastTimetick = (feeding_session_timestamp, patient_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [timetick, setTimetick] = useState();

    useEffect(() => {
        if (feeding_session_timestamp && patient_id)
            getTimetick();
    }, [feeding_session_timestamp, patient_id])

    const getTimetick = async () => {
        setIsLoading(true);
        const response = await GetLastTimetick(feeding_session_timestamp, patient_id)
        setTimetick(response);
        setIsLoading(false);
    }

    return {
        isLoading,
        timetick,
    }
}
