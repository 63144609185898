import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FeedingChart } from '../Charts/FeedingChart';
import { BiomarkersSection } from './PatientCardComponents/BiomarkersSection';
import { FeedingDetailsSection } from './PatientCardComponents/FeedingDetailsSection';
import { BiomarkersChart } from '../Charts/BiomarkersChart';
import { SessionSelectorSection } from './PatientCardComponents/SessionSelectorSection';
import UpdateFavoritePatient from '../../data_layer/repositories/Patients/UpdateFavoritePatient';
import {useGetFeedingDetails} from "../CustomHooks/useGetFeedingDetails";
import {useGetFlags} from "../CustomHooks/useGetFlags";
import {useGetBiomarkersListByPatientId} from "../CustomHooks/useGetBiomarkersListByPatientId";


export const PatientCardV2 = ({ patient, showDetails, showChart, user, favoritePatients, refreshFavorites }) => {

    const navigate = useNavigate();

    const [cardSize, setCardSize] = useState(4);
    //const [feeding, setFeeding] = useState()
    const [currentTimestamp, setCurrentTimestamp] = useState();
    const [isFavorite, setIsFavorite] = useState(false);

    const {feeding} = useGetFeedingDetails(currentTimestamp);

    const {flags} = useGetFlags(currentTimestamp);

    const {biomarkers, getBiomarkers} = useGetBiomarkersListByPatientId(patient.patient_id);

    const onCardClick = () => {
        navigate(`patients/${patient.patient_id}`, {
            state: { patient }
        });
    }

    const handleSavePatient = () => {
        //setIsFavorite(!isFavorite);
        UpdateFavoritePatient(patient.patient_id, user.user_id, refreshFavorites);
    }

    // useEffect(() => {
    //     setFeeding(patient?.feedings.find((feeding) => feeding.timestamp === currentTimestamp));
    // }, [currentTimestamp]);

    useEffect(() => {
        getBiomarkers();
    }, [patient]);

    useEffect(() => {
        setIsFavorite(favoritePatients.find((fp) => fp.patient_id === patient.patient_id));
    }, [favoritePatients]);

    useEffect(() => {
        if (showChart) {
            setCardSize(12);
        }
        else if (showDetails && !showChart) {
            setCardSize(6);
        }
        else {
            setCardSize(4)
        }

        if (showDetails) {
            setCurrentTimestamp(patient?.feedings[0].timestamp)
        }
        else {
            if (showChart) {
                setCurrentTimestamp(patient?.feedings[0].timestamp);
            }
            else {
                setCurrentTimestamp(null);
            }
        }

    }, [showDetails, showChart]);

    function onDateClick(timestamp) {
        setCurrentTimestamp(timestamp)
    }


    return (
        <div className={`col-md-${cardSize} mb-4`}>
            <div className="w-100 rounded-4 bg-white px-0">
                <div className="row">
                    <div className={`pe-0 py-3 border-end ${showChart ? 'col-lg-5' : 'col-lg-11'}`}>
                        {/*<!-- Header -->*/}
                        <div className="mt-1 d-flex">
                            <div className="px-4 pb-2 d-flex align-items-center ">
                                <div className="d-flex align-items-center gap-2 border-end pe-3">
                                    <div className="circle fw-bold text-md d-flex align-items-center justify-content-center">{patient?.initials}</div>
                                    <p className="fw-bold m-0">ID:{patient?.ehr_patient_ref}</p>
                                </div>
                                <div className="align-items-center d-flex ps-3">
                                    <p className="text-sm mb-0">Name: {patient?.name}
                                        <span className="mx-1">|</span> {patient?.gestational_age} GA
                                        <span className="mx-1">|</span> {`${patient?.birth_weight} ${patient?.weight_uom} birth wt`}
                                        <span className="mx-1">|</span> Maturation: {feeding?.biomarkers?.find((bm) => bm.biomarker_name === "MATURATION")?.biomarker_value || '--'}</p>
                                </div>


                            </div>
                            <div className="d-flex pe-1 align-items-center  ms-auto date-selector" onClick={onCardClick}>
                                <p className="fw-semibold mb-2 text-xs">View details</p>
                                <i className='bx bx-right-arrow-alt text-2xl mb-2 ms-1'></i>
                            </div>
                        </div>
                        {/*<!-- Sub Header -->*/}

                        <SessionSelectorSection currentTimestamp={currentTimestamp} feedings={patient?.feedings} onDateClick={onDateClick} />


                        {/*<!-- Content -->*/}
                        {showDetails &&
                            <div className="border-top d-flex">
                                <div className="px-4 pt-3 w-100 d-flex align-items-stretch">

                                    <FeedingDetailsSection feeding={feeding} />
                                    <BiomarkersSection biomarkers={biomarkers?.feedings?.find((feed) => feed.timestamp === currentTimestamp)?.biomarkers} />

                                </div>
                            </div>
                        }
                    </div>
                    <div className={`py-3 chart-col-padding-right ${showChart ? 'col-lg-7' : 'col-lg-1'}`}>
                        <div className="d-flex justify-content-center h-100 w-100">
                            {showChart && <>
                                <div className="border-end d-flex flex-column pt-2 px-4 chart-mindex-box">
                                    <p className="fw-semibold mb-2 text-xs">Maturation Index | <span className="text-muted">Weight (g)</span></p>
                                    <BiomarkersChart feedings={biomarkers?.feedings} conceptionDate={patient?.date_of_conception} />
                                </div>
                                <div className="d-flex mx-auto flex-column pt-2 px-4 flex-1" onClick={onCardClick}>
                                    <div className="d-flex align-items-center">
                                        <p className="fw-semibold mb-2 text-xs">Oral Cavity Pressure (mmHg) | <span className="text-muted">Temperature (c)</span></p>
                                        <div className="ms-auto d-flex gap-3 align-items-center">
                                            <p className="fw-semibold mb-2 text-xs"><i className='bx bxs-circle text-pressure'></i> Suck & Swallow / sec</p>

                                            <p className="fw-semibold mb-2 text-xs"><i className='bx bxs-circle text-breathing'></i> Breathing / sec </p>
                                        </div>
                                    </div>
                                    <FeedingChart feeding={feeding} flags={flags} isLightGraph={true} />
                                </div></>
                            }
                            <div className="d-flex flex-column gap-3 align-content-start">

                                <i className={`bx ${isFavorite ? 'bxs-bookmark text-secondary' : 'bx-bookmark text-gray'} text-3xl card-icon`} onClick={handleSavePatient}></i>
                                {cardSize !== 4 &&
                                    <>
                                         {/*<i className='bx bx-filter text-3xl card-icon' ></i>*/}
                                        <div className="position-relative mt-2">
                                            <i className={`bx bx-flag text-3xl ${flags?.length > 0 ? 'text-secondary' : 'text-gray'}`}></i>
                                            {flags?.length > 0 &&
                                                <div className={`dot-number text-white d-flex align-items-center justify-content-center rounded-circle position-absolute text-xxs`}>
                                                    {flags?.length}
                                                </div>
                                            }
                                        </div>

                                        <div className="position-relative mt-2">
                                            <i className={`bx bx-comment text-3xl ${feeding?.note ? 'text-secondary' : 'text-gray'}`}></i>
                                            {feeding?.note &&
                                                <div className={`dot-number text-white d-flex align-items-center justify-content-center rounded-circle position-absolute text-xxs`}>
                                                    1
                                                </div>
                                            }
                                        </div>
                                        {/* <i className='bx bx-comment text-2xl card-icon position-relative icon-left-2px'></i> */}

                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
