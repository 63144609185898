import { PostApi, VerifyUserPath } from "../../apis/Api";

async function VerifyUser(
  email,
  name,
  facility_id)
{

  const payload = {
    email, name, facility_id
  }

  await PostApi(VerifyUserPath, payload)
    .then(response => response.json());

}

export default VerifyUser;