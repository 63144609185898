import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import { FiChevronDown, FiChevronUp } from "react-icons/fi";


export const CollapsibleBorderCard = ({ children, title }) => {

    const [showContent, setShowContent] = useState(true);

    const onArrowClick = () => {
        setShowContent(!showContent)
    }

    return (

        <div className="neo_inner_card_container">
            {/* <div className="neo_card_drop_container">
                <h5 className="">
                    {title}
                </h5>
                <span className='click' onClick={onArrowClick}>
                    {showContent ? <FiChevronUp className='neo_icon' size={30} /> : <FiChevronDown className='neo_icon' size={30} />}
                </span>
            </div> */}
            {showContent && <div className="mt-2">
                {children}
            </div>
            }
        </div>

    )
}
