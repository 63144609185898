import '../../styles/Clinician.css'
import { Link, useLocation } from "react-router-dom"
import { useContext, useState } from 'react';
import { AuthContext } from "../../auth";
import { Roles } from '../../data_layer/repositories/Users/UserRoles'
import { TimeOutHandler } from "../IdleTimeOutHandler/TimeOutHandler";
import { MillisOnMinute, isTimeoutActive } from "../../utilities/Constants";
import { ProfileDropdown } from "./ProfileDropdown";

export const ClinicianNavbar = ({ setSearch, setSort, setShowChart, setShowDetails, setShowSaved, showChart, showDetails, showSaved }) => {

    const { logout, user, facility } = useContext(AuthContext);

    const [isActive, setIsActive] = useState(true);
    const [isLogout, setIsLogout] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const { pathname } = useLocation();

    function onSearchChange({ target }) {
        setSearchValue(target.value);
        setSearch(target.value);
    }

    function onSelectSortChanged({ target }) {
        setSort(target.value);
    }

    function handleShowDetailsChanged() {
        setShowDetails(!showDetails);
    }

    function handleShowChartChanged() {
        setShowChart(!showChart);
    }

    function handleShowSavedChanged() {
        setShowSaved(!showSaved);
    }

    return (

        <div className="navbar navbar-expand-lg navbar-light bg-light bg-white py-2 px-4">
            {
                isTimeoutActive && <TimeOutHandler
                    onActive={() => { setIsActive(true) }}
                    onIdle={() => { setIsActive(false) }}
                    onLogout={() => {
                        setIsLogout(true);
                        logout();
                    }}
                    timeOutInterval={facility.web_timeout_session * MillisOnMinute} />
            }
            <div className="d-flex w-100 align-items-center">
                <div className="w-25 d-flex align-items-center">
                    <Link to="/" className='navbar-brand neo_nav_brand'>
                        <img
                            src={require('../../assets/Neoneuro-FF.png')}
                            alt="Neoneur Logo"
                            className="w-100"
                        />
                    </Link>
                    <div className="ms-5 me-auto">
                        <p className="m-0 fw-bold">{facility.name}</p>
                    </div>
                </div>

                {!pathname.includes('patients') &&
                    <>
                        <div className="w-25 d-flex align-items-stretch">
                            <div className="rounded-4 ms-auto align-items-center p-2 d-flex border border-2 border-dark">
                                <input type="text" name="" id="" className="input-wh-style"
                                    placeholder="Search by Patient Name or ID"
                                    onChange={onSearchChange}
                                    value={searchValue} />
                                <i className='bx bx-search ms-3 text-xl'></i>
                            </div>
                            <div className="ms-2 w-35">
                                <select className="rounded-4 border border-2 border-dark p-2 h-100 w-100"
                                    name=""
                                    id=""
                                    onChange={onSelectSortChanged}>
                                    <option value="most-recent" defaultValue={true}>Most Recent</option>
                                    <option value="a-z" >A-Z</option>
                                    <option value="z-a" >Z-A</option>
                                    <option value="id" >ID</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex align-items-center w-50">

                            <div className="d-flex mx-auto align-items-center">
                                <div className="mx-4">
                                    <div className="d-flex align-items-center form-check form-switch">
                                        <label className="form-check-label fw-semibold" htmlFor="flexSwitchCheckDefault">Show sessions details</label>
                                        <input
                                            className="form-check-input ms-3"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            checked={showDetails}
                                            onChange={handleShowDetailsChanged} />
                                    </div>
                                </div>
                                <div className="me-4">
                                    <div className="d-flex align-items-center form-check form-switch">
                                        <label className="form-check-label fw-semibold" htmlFor="flexSwitchCheckDefault2">Show charts</label>
                                        <input
                                            className="form-check-input ms-3"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault2"
                                            checked={showChart}
                                            onChange={handleShowChartChanged} />
                                    </div>
                                </div>
                                <div className="">
                                    <div className="d-flex align-items-center form-check form-switch">
                                        <label className="form-check-label fw-semibold" htmlFor="flexSwitchCheckDefault3">Show saved</label>
                                        <input
                                            className="form-check-input ms-3"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault3"
                                            checked={showSaved}
                                            onChange={handleShowSavedChanged} />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </>
                }
                <div className="ms-auto d-flex align-items-center">
                    <ProfileDropdown className="text-3xl text-color mx-5" user={user} facility={facility} logout={logout} />
                </div>
            </div>
        </div>
    )
}