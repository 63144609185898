import './LoginPage.css';
import '../../styles/Shared.css'
import Alert from 'react-bootstrap/Alert';

import { useContext, useEffect, useState } from "react";

import ValidatedFieldEntry from '../../components/ValidatedFieldEntry/ValidatedFieldEntry';
import { AuthContext } from '../../auth';
import { useLocation, useNavigate, redirect } from 'react-router-dom';
import { 
  CheckIsMissingAndLength, 
  CheckIsNumeric, 
  InputValidationErrorStates 
} from '../../components/Modals/UpdateEntityModals/InputValidation';
import { Auth } from 'aws-amplify';
import { LoginTemplate } from '../../components/Templates/LoginTemplate';
import GetUser from '../../data_layer/repositories/Users/GetUser';
import { Loading } from '../../components/Modals/GenericModals/Loading';
import LocalStorage from '../../utilities/Storage';

// NOT IN USE, LEGACY CODE

function LoginPageOTP(props) {

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const [loginError, setLoginError] = useState();
  const [changePassword, setChangePassword] = useState(false);

  const navigate = useNavigate();

  const { state } = useLocation();
  const [cognitoUser, setCognitoUser] = useState({});

  const [loading, setLoading] = useState(false)

  const { login } = useContext(AuthContext);

  useEffect(() => {
    //const cognitoUser = await Auth.signIn(state.awsUsername, state.password);
    setCognitoUser(JSON.parse(LocalStorage.getItem("cognitoUser")));  

    console.log(state.cognitoUser);
    // console.log(state.accessToken);
  }, []);

  useEffect(() => {
    if (cognitoUser){
      console.log("COGNITO USER = " + JSON.stringify(cognitoUser));
    }
    else{
      // navigate to facility
      navigate('/');
    }
  }, [cognitoUser]);


  function attemptLogin(event) {
    event.preventDefault();
    setLoginError();
    const cleanCode = code.trim();

    if (!cleanCode) {
      setCodeError(InputValidationErrorStates.RequiredAndMissing);
      return;
    }

    if (CheckIsNumeric(cleanCode) !== InputValidationErrorStates.NoError) {
      setCodeError(InputValidationErrorStates.NeedsToBeNumeric);
      return;
    }

    if (CheckIsMissingAndLength(cleanCode, 6, 6) !== InputValidationErrorStates.NoError) {
      setCodeError(InputValidationErrorStates.IncorrectMinLength);
      return;
    }


    signIn(cleanCode);
  }

  function handleCodeChanged(value) {
    setCode(value);
    setCodeError('');
  }

  const signIn = async (code) => {
    setLoading(true);

    //const awsUsername = `${state.username}@${state.facility.id}`;

    try {
      console.log("TRY:" + cognitoUser.challengeName);
      
      //Verifying the user need to change the password
      if (cognitoUser.challengeName === 'SMS_MFA') {

        console.log("MFA")

        const loginUser = await Auth.confirmSignIn(
          cognitoUser, // Return object from Auth.signIn()
          code, // Confirmation code
          'SMS_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );

        if (loginUser) {

          const session = await Auth.currentSession();
          const accessToken = session.getAccessToken().getJwtToken();
          console.log("SESSION = " + JSON.stringify(session));
          console.log("TOKEN = " + JSON.stringify(accessToken));

          if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // Navigate to change password
            // send current user and facility
            setLoading(false)
            navigate('/change-password', {
              state: {
                facility: state.facility,
                password: code,
                username: state.username,
                awsUsername: state.awsUsername
              }
            });
          }
          else{
            getUser();
          }
        }
        else{
          console.log("NO LOGIN USER");
          setLoading(false)
          setLoginError(`We are sorry, but can't login at this time`);
        }
      }
      else {
        // Login user 
        getUser();
      }

    } catch (error) {
      setLoading(false)
      console.log(error)
      if (error.message === 'User is disabled.'){
        setLoginError('User credentials are inactive.');
      }
      else {
        setLoginError(error.message);
      }
    }
    
  }

  async function getUser() {

    // const session = await Auth.currentSession().promise();
    // //const idToken = session.getIdToken().getJwtToken();
    // const accessToken = session.getAccessToken().getJwtToken();
    // //const refreshToken = session.getRefreshToken().getToken();
    // console.log(`accessToken: ${accessToken}`);
    // LocalStorage.setItem("accessToken", accessToken);


    GetUser(state.username, state.facility.id).then((user) => {
      setLoading(false)
      if (user.user_id) {
        login(user, state.facility);
        navigate('/dashboard', { replace: true });
      }
      else {
        setLoginError(`We are sorry, but can't login at this time`)
      }
    });
  }

  return (
    <LoginTemplate>

      <Loading isLoading={loading} />

      <center>
        <h4>Verification</h4>
        <p>You will get a <b>One Time Code</b> on your phone number { `${cognitoUser?.challengeParam?.CODE_DELIVERY_DESTINATION || ""}` }</p>

      </center>

      <ValidatedFieldEntry
        labelName="CODE"
        placeholder="* * * * * *"
        value={code}
        inputChanged={handleCodeChanged}
        entryMaxLength={6}
        entryMinLength={6}
        errorState={codeError}
      />

      <Alert variant='danger' show={loginError ? true : false} >
        {loginError}
      </Alert>

      <center>
        <button onClick={attemptLogin} className="LoginButton neo_btn_primary">Verify</button>
      </center>

      

    </LoginTemplate>
  );
}

export default LoginPageOTP;