import { useParams } from 'react-router-dom';
import { LuBaby } from "react-icons/lu";

import { FeedingSessionCard } from '../../components/Cards/FeedingSessionCard';
import { CommentsCard } from '../../components/Cards/CommentsCard';
import { useGetPatient } from '../../components/CustomHooks/useGetPatient';
import { Loading } from '../../components/Loading/Loading';
import { AuthContext } from '../../auth';
import { useContext, useEffect, useState } from 'react';
import { Roles } from '../../data_layer/repositories/Users/UserRoles';
import FeedingsTable from '../../components/Tables/FeedingsTable';
import { useGetBiomarkersListByPatientId } from '../../components/CustomHooks/useGetBiomarkersListByPatientId';
import { Button } from 'react-bootstrap';
import { elements } from 'chart.js';

export const PatientDetails = () => {

    const { patient_id } = useParams();

    const { patient, isLoading } = useGetPatient(patient_id);

    const { biomarkers } = useGetBiomarkersListByPatientId(patient_id);

    const { user } = useContext(AuthContext);

    const [feedingsToShow, setFeedingsToShow] = useState([0]);


    const addFeedingCard = () => {
        setFeedingsToShow((prevFeedings) => [...prevFeedings, prevFeedings.length]);
    };

    const removeFeedingCard = () => {
        if (feedingsToShow.length > 1) {
            setFeedingsToShow((prevFeedings) => prevFeedings.slice(0, -1)); // Safely remove last card
        }
    }



    return (
        <div className="neo_container">
            {
                isLoading ? <Loading isLoading={isLoading} />
                    : <>
                        {patient && user.role === Roles.Clinician && <>

                            {/* <MaturationCurveCard patient={patient}/> */}

                            {feedingsToShow.map((feeding, index) => (
                                <FeedingSessionCard 
                                    key={index} 
                                    patient={patient} 
                                    index={index} 
                                    total={feedingsToShow.length}
                                    addFeedingCard={addFeedingCard}
                                    removeFeedingCard={removeFeedingCard}/>
                            ))}

                            <CommentsCard patient={patient} className="neo_ut_mb_24"/>
                        </>}




                        {patient && user.role === Roles.NeoneurAdmin && <>

                            <div className='neo_card_container neo_ut_mt_24_mob'>

                                <FeedingsTable
                                    data={patient.feedings}
                                    facility={patient.facility_name}
                                    patient={patient} />
                            </div>

                        </>}
                    </>
            }
        </div>
    )
}
