import './LoginPage.css';
import '../../styles/Shared.css'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { useContext, useEffect, useState } from "react";

import ValidatedFieldEntry from '../../components/ValidatedFieldEntry/ValidatedFieldEntry';
import { AuthContext } from '../../auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckIsMissingAndLength, CheckIsNumeric, InputValidationErrorStates } from '../../components/Modals/UpdateEntityModals/InputValidation';
import { Amplify, Auth } from 'aws-amplify';
import { LoginTemplate } from '../../components/Templates/LoginTemplate';
import GetUser from '../../data_layer/repositories/Users/GetUser';
import { Loading } from '../../components/Modals/GenericModals/Loading';
import GetFacilities from '../../data_layer/repositories/Facilities/GetFacilities';
import LocalStorage from '../../utilities/Storage';
import ValidatedFieldEntryWithRules from '../../components/ValidatedFieldEntry/ValidatedFieldEntryWithRules';

const region = process.env.REACT_APP_AWS_REGION;
const userPoolId = process.env.REACT_APP_AWS_POOL_ID;
const userPoolWebClientId = process.env.REACT_APP_AWS_POOL_CLIENT_ID;

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId,
  },
});


function LoginPage(props) {

  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const [facility, setFacility] = useState()

  const [loginError, setLoginError] = useState();
  // const [changePassword, setChangePassword] = useState(false);

  const navigate = useNavigate();

  const { state, pathname } = useLocation();
  const [user, setUser] = useState()

  const { login } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const [cognitoUser, setCognitoUser] = useState({});

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const [authenticationStage, setAuthenticationStage] = useState('LOGIN');

  // Change password 

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);

  const [isValidPassword, setIsValidPassword] = useState(false);

  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);


  useEffect(() => {
    if (pathname === '/login') {
      if (state?.facility)
        setFacility(state.facility);
      else
        navigate('/')
    }
    else {
      GetFacilities().then((facilities) => {
        setFacility(facilities.find((facility) => facility?.name === 'Neoneur'))
      });
    }
  }, [])




  function attemptLogin(event) {
    event.preventDefault();
    setLoginError();
    const cleanUsername = username.trim();
    const cleanPassword = password.trim();

    if (!cleanUsername) {
      setUsernameError(InputValidationErrorStates.RequiredAndMissing);
      return;
    }

    if (!cleanPassword) {
      setPasswordError(true);
      return;
    }

    const pattern = /^[\p{L}\p{N}]+$/u;
    if (!pattern.test(cleanUsername)) {
      setUsernameError(InputValidationErrorStates.IncorrectUsernameFormat);
      return;
    }

    signIn(cleanUsername, cleanPassword);
  }

  function handleUsernameChanged(value) {
    setUsername(value);
    setUsernameError('');
  }

  function handlePasswordChanged(value) {
    setPassword(value);
    setPasswordError(false);
  }

  const signIn = async (username, password) => {
    setLoading(true)

    const awsUsername = `${username}@${facility?.id}`;

    try {
      const cognitoUserTemp = await Auth.signIn(awsUsername, password);

      setCognitoUser(cognitoUserTemp);

      // const attributes = {
      //   state: {
      //     facility: facility,
      //     password: password,
      //     username: username,
      //     awsUsername: awsUsername
      //   }
      // }

      // Verifying the user need to change the password
      if (cognitoUserTemp.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Navigate to change password
        // send current user and facility
        setLoading(false)
        //navigate('/change-password', attributes);
        setAuthenticationStage('CHANGE_PASSWORD');
      }
      else if (cognitoUserTemp.challengeName === 'SMS_MFA') {
        // Navigate to One Time Passcode
        LocalStorage.setItem("cognitoUser", JSON.stringify(cognitoUserTemp));
        setLoading(false)
        //navigate('/login-otp', attributes)
        setAuthenticationStage('MFA');
      }
      else if (cognitoUserTemp.challengeName === 'CUSTOM_CHALLENGE') {
        // Navigate to One Time Passcode
        const code = prompt('Enter the verification code sent to your email:');
        await Auth.sendCustomChallengeAnswer(cognitoUserTemp, code);
        setLoading(false)
        //navigate('/login-otp', attributes)
      }
      else {
        // Login user 

        getUser();
      }

    } catch (error) {
      // console.log(error);
      setLoading(false)
      if (error.message === 'User is disabled.')
        setLoginError('User credentials are inactive.');
      else
        setLoginError(error.message);
    }
  }

  function getUser() {
    GetUser(username, facility?.id).then((user) => {
      setLoading(false)
      if (user.user_id) {
        login(user, facility);
        navigate('/dashboard', { replace: true });
      }
      else {
        setLoginError(`We are sorry, but can't login at this time`)
      }
    });
  }

  function oneTimeCodeVerify(event) {
    event.preventDefault();
    setLoginError();
    const cleanCode = code.trim();

    if (!cleanCode) {
      setCodeError(InputValidationErrorStates.RequiredAndMissing);
      return;
    }

    if (CheckIsNumeric(cleanCode) !== InputValidationErrorStates.NoError) {
      setCodeError(InputValidationErrorStates.NeedsToBeNumeric);
      return;
    }

    if (CheckIsMissingAndLength(cleanCode, 6, 6) !== InputValidationErrorStates.NoError) {
      setCodeError(InputValidationErrorStates.IncorrectMinLength);
      return;
    }


    verifyCode(cleanCode);
  }

  async function verifyCode(code) {
    setLoading(true);

    //const awsUsername = `${state.username}@${state.facility.id}`;

    try {
      // console.log("TRY:" + cognitoUser.challengeName);

      // console.log("MFA")

      const loginUser = await Auth.confirmSignIn(
        cognitoUser, // Return object from Auth.signIn()
        code, // Confirmation code
        'SMS_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );

      if (loginUser) {

        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        LocalStorage.setItem("accessToken", accessToken);
        // // console.log("SESSION = " + JSON.stringify(session));
        // // console.log("TOKEN = " + JSON.stringify(accessToken));

        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // Navigate to change password
          // send current user and facility
          setLoading(false)
          navigate('/change-password', {
            state: {
              facility: state.facility,
              password: code,
              username: state.username,
              awsUsername: state.awsUsername
            }
          });
        }
        else {
          getUser();
        }
      }
      else {
        // console.log("NO LOGIN USER");
        setLoading(false)
        setLoginError(`We are sorry, but can't login at this time`);
      }


    } catch (error) {
      setLoading(false)
      // console.log(error)
      if (error.message === 'User is disabled.') {
        setLoginError('User credentials are inactive.');
      }
      else {
        setLoginError(error.message);
      }
    }


  }

  function handleCodeChanged(value) {
    setCode(value);
    setCodeError('');
  }

  // CHANGE PASSWORD FUNCTIONS

  const handleNewPasswordChanged = (value) => {
    setNewPassword(value);
    setNewPasswordError('');
  }

  const handleConfirmNewPasswordChanged = (value) => {
    setConfirmNewPassword(value);
    setConfirmNewPasswordError('');
  }

  const onClickChangePasswordButton = (event) => {
    event.preventDefault();

    // validations
    const cleanNewPassword = newPassword.trim();
    const cleanConfirmPassword = confirmNewPassword.trim();

    if (!cleanNewPassword) {
      setNewPasswordError(InputValidationErrorStates.RequiredAndMissing);
      return;
    }

    if (!isValidPassword) {
      setNewPasswordError(InputValidationErrorStates.IncorrectPasswordFormat);
      return;
    }

    if (!cleanConfirmPassword) {
      setConfirmNewPasswordError(InputValidationErrorStates.RequiredAndMissing);
      return;
    }

    if (cleanNewPassword !== cleanConfirmPassword) {
      setConfirmNewPasswordError(InputValidationErrorStates.MustBeEqual);
      return;
    }

    if (cleanNewPassword === state.password) {
      setNewPasswordError(InputValidationErrorStates.PasswordMustBeDifferent);
      return;
    }

    // SignIn

    signInWithNewPassword(cleanNewPassword);

  }

  const signInWithNewPassword = async (password) => {
    setLoading(true)
    try {
      

        const completeNewPasswordResponse = await Auth.completeNewPassword(cognitoUser, newPassword);

        if (completeNewPasswordResponse) {
          if (cognitoUser.challengeName === 'SMS_MFA') {
            // Navigate to One Time Passcode
            setLoading(false);
            setAuthenticationStage('MFA');
          }
          else {
            // Login user 
            getUser();
          }
        }
        else {
          setLoading(false)
          setLoginError(`We are sorry, but can't login at this time`);
        }
      
    }
    catch (error) {
      setLoading(false)
      setLoginError(error.message);

    }
  }


  return (
    <LoginTemplate>

      <Loading isLoading={loading} />

      {
        authenticationStage === 'LOGIN' &&

        <>
          <center>
            {pathname === '/login' ? <h5>{facility?.name}</h5> : <h5>Neoneur Admin</h5>}
          </center>

          <ValidatedFieldEntry
            labelName="USERNAME"
            placeholder="Username"
            value={username}
            inputChanged={handleUsernameChanged}
            errorState={usernameError}
          />
          <ValidatedFieldEntry
            labelName="PASSWORD"
            placeholder="Password"
            isPassword
            value={password}
            inputChanged={handlePasswordChanged}
            errorState={passwordError
              ? InputValidationErrorStates.RequiredAndMissing
              : ''}
          />

          <Alert variant='danger' show={loginError ? true : false} >
            {loginError}
          </Alert>

          <center>
            <button onClick={attemptLogin} className="LoginButton neo_btn_primary">Login</button>
          </center>
        </>


      }


      {/* OTC SCREEN */}
      {
        authenticationStage === 'MFA' &&


        <>
          <center>
            <h4>Verification</h4>
            <p>You will get a <b>One Time Code</b> on your phone number {`${cognitoUser?.challengeParam?.CODE_DELIVERY_DESTINATION || ""}`}</p>

          </center>


          <ValidatedFieldEntry
            labelName="CODE"
            placeholder="* * * * * *"
            value={code}
            inputChanged={handleCodeChanged}
            entryMaxLength={6}
            entryMinLength={6}
            errorState={codeError}
          />

          <Alert variant='danger' show={loginError ? true : false} >
            {loginError}
          </Alert>

          <center>
            <button onClick={oneTimeCodeVerify} className="LoginButton neo_btn_primary">Verify</button>
          </center>

        </>
      }

      {/* CHANGE PASSWORD SCREEN */}
      {
        authenticationStage === 'CHANGE_PASSWORD' &&
        <>
          <center>
            <h4>Change your password</h4>
          </center>

          <ValidatedFieldEntryWithRules
            labelName="NEW PASSWORD"
            placeholder="New Password"
            isPassword
            value={newPassword}
            inputChanged={handleNewPasswordChanged}
            isValidPassword={setIsValidPassword}
            errorState={newPasswordError}
          />

          <ValidatedFieldEntry
            labelName="CONFIRM PASSWORD"
            placeholder="Confirm Password"
            isPassword
            value={confirmNewPassword}
            inputChanged={handleConfirmNewPasswordChanged}
            errorState={confirmNewPasswordError}
          />


          <Alert variant='danger' show={loginError ? true : false} >
            {loginError}
          </Alert>


          <center>
            <button onClick={onClickChangePasswordButton} className="LoginButton neo_btn_primary">Login</button>
          </center>
        </>
      }

    </LoginTemplate>
  );
}

export default LoginPage;