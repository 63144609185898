import { GetApi, GetCommentsByPatientPath } from "../../apis/Api";

async function GetComments(patient_id) {

  var comments = await GetApi(GetCommentsByPatientPath + "/" + patient_id)
    .then(response => response.json())
    .then(data => data.result); // TODO: Add error checking for multiple responses.
 
  return comments;
};

export default GetComments;