
import { Route, Routes } from "react-router-dom";
import { useContext } from "react";

import LoginPage from "../pages/LoginPage/LoginPage";
import SelectFacilityPage from "../pages/SelectFacilityPage/SelectFacilityPage";

import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

import { AuthContext } from "../auth";
import { ChangePasswordPage } from "../pages/ChangePassword/ChangePasswordPage";

import { ITAdminRoutes } from "./ITAdminRoutes";
import { ClinicalAdminRoutes } from "./ClinicalAdminRoutes";
import { ClinicianRoutes } from "./ClinicianRoutes";
import { NeoneurAdminRoutes } from "./NeoneurAdminRoutes";
import LoginPageOTP from "../pages/LoginPage/LoginPageOTP";
import { v4 as uuidv4 } from 'uuid';


export const AppRouter = ({ setToken }) => {


    const {user} = useContext( AuthContext );

    const adminUUID = process.env.REACT_APP_ADMIN_UUID || uuidv4();
    
    const adminURL = `/admin/${adminUUID}`

    return (
        <>
            <Routes>
                <Route path="/*" element={
                    <PublicRoute>
                        <Routes>
                            <Route path="/*" element={ <SelectFacilityPage /> }/>
                            <Route path="/login" element={ <LoginPage /> }/>
                            <Route path={adminURL} element={ <LoginPage /> }/>
                            {/* <Route path="/change-password" element={ <ChangePasswordPage /> }/>
                            <Route path="/login-otp" element={ <LoginPageOTP /> }/> */}
                        </Routes>
                        {/* <LoginPage /> */}
                    </PublicRoute>
                } />

                <Route path="dashboard/*" element={
                    <PrivateRoute>
                        {
                            user?.role === 'it-admin' 
                            ? <ITAdminRoutes /> 
                            : user?.role === 'clinical-admin'
                            ? <ClinicalAdminRoutes />
                            : user?.role === 'clinician'
                            ? <ClinicianRoutes />
                            : <NeoneurAdminRoutes/>
                        }
                        
                    </PrivateRoute>
                }/>


            </Routes>
        
        </>
    );
}