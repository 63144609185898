import { useEffect, useState } from 'react'
import GetComments from '../../data_layer/repositories/Comments/GetCommentsByPatients'

export const useGetCommentsByPatient = (patient_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        getComments();
    }, []);

    const getComments = async () => {
        const resetResponse = await GetComments(patient_id);
        setComments(resetResponse);
        setIsLoading(false);
    }

    return {
        isLoading,
        comments
    }
}
